import Vue from 'vue'
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import axios from 'axios'
Vue.config.productionTip = false

Vue.prototype.$imgurl = "https://devcdn.tectalik.com/compliance/uploads/";
Vue.prototype.$imgurl_original = "https://s3.ap-south-1.amazonaws.com/devcdn.tectalik.com/compliance/uploads/original_";

let api_subdomain = "";
let api_analytics = "";
const node_env = process.env.NODE_ENV.toLowerCase().trim();
if (node_env === "production") {
  Vue.prototype.apiurl = "https://compliance-api.tectalik.com/api/";
  Vue.prototype.$imgurl = "https://compliance-api.tectalik.com/uploads/";
  api_subdomain = "https://compliance-api.tectalik.com/";
  api_analytics = "https://analytics-api.tectalik.com/api/";
} else if (node_env === "development") {
  Vue.prototype.apiurl = "https://compliance-api-staging.tectalik.com/api/";
  Vue.prototype.$imgurl =
    "https://compliance-api-staging.tectalik.com/uploads/";
  api_subdomain = "https://compliance-api-staging.tectalik.com/";
  api_analytics = "https://staging-business-health-api.tectalik.com/api/";
} else if (node_env === "lan") {
  api_subdomain = "http://192.168.0.120:4001/";
  Vue.prototype.apiurl = api_subdomain + "api/";
  Vue.prototype.$imgurl = api_subdomain + "uploads/";
} else if (node_env == "lan_secure") {
  api_subdomain = "https://192.168.0.120:4001/";
  Vue.prototype.apiurl = api_subdomain + "api/";
  Vue.prototype.$imgurl = api_subdomain + "uploads/";
} else {
  Vue.prototype.apiurl = "http://localhost:4001/api/";
  Vue.prototype.$imgurl = "http://localhost:4001/uploads/";
  api_subdomain = "http://localhost:4001/";
}


router.beforeEach((to, from, next)=>{


  var authRequired = to.meta.auth; 
  var token = localStorage.getItem("TOKEN");
 
  if (!authRequired && token) {

    next("/");
    return;
  }


  
  next();
});
axios.interceptors.request.use(function (config) {
  
  var url = config.url;
  config.url = '';
  if(url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
  config.url = url;
  } else {
    config.url = Vue.prototype.apiurl + url;
  }
  var token = localStorage.getItem("TOKEN");

  if(token)
  {
    config.headers.Authorization = 'Bearer ' +token;
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

